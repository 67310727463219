import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  const links = [
    {
      name: "About",
      url: "/about/",
    },
    {
      name: "Services",
      url: "/services/",
    },
    {
      name: "Contact",
      url: "/contact/",
    },
  ]
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="single-footer-widget">
              <h6>About Us</h6>
              <p>
                One stop solution for all your home or corporate needs and
                maintenance.
              </p>
              <p className="footer-text d-none d-md-block">
                Copyright &copy; 2021 All rights reserved | Madhuram Interiors
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 social-widget">
            <div className="single-footer-widget">
              <h6>Quick Links</h6>
              <ul className="quickLinks">
                {links.map((link, index) => {
                  return (
                    <li>
                      <Link to={link.url}>{link.name}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 social-widget">
            <div className="single-footer-widget">
              <h6>Contact Us</h6>
              {/* <p>Let us be social</p> */}
              <a href="tel:+919841787093" target="_blank">
                <h5 style={{ color: "#fff", marginBottom: "10px" }}>
                  call@(+91) 9841787093
                </h5>
              </a>
              <a href="https://wa.me/+919655176317" target="_blank">
                <h5 style={{ color: "#fff", marginBottom: "10px" }}>
                  whatsapp@(+91) 9655176317
                </h5>
              </a>

              <h5 style={{ color: "#fff", marginBottom: "10px" }}>
                <a
                  style={{ color: "#fff", marginBottom: "10px" }}
                  href="mailto:contact@madhuraminteriors.com"
                  className="__cf_email__"
                  target="_blank"
                >
                  contact@madhuraminteriors.com
                </a>
              </h5>
              <p>Enquiry us your query anytime!</p>
              {/* <div className="footer-social d-flex align-items-center">
                                <a href="#"><i className="fa fa-facebook"></i></a>
                                <a href="#"><i className="fa fa-twitter"></i></a>
                                <a href="#"><i className="fa fa-dribbble"></i></a>
                                <a href="#"><i className="fa fa-behance"></i></a>
                            </div> */}
            </div>
          </div>
          <div className="col-md-12 d-md-none">
            <p className="footer-text">
              Copyright &copy; 2021 All rights reserved | Madhuram Interiors
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
