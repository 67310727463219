import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Image from "../components/Image"
import $ from "jquery"
import "../assets/css/linearicons.css"
import "../assets/css/font-awesome.min.css"
import "../assets/css/bootstrap.css"
import "../assets/css/magnific-popup.css"
import "../assets/css/nice-select.css"
import "../assets/css/animate.min.css"
import "../assets/css/owl.carousel.css"
import "../assets/css/main.css"

const Header = () => {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50)
    })
    if ($("#nav-menu-container").length) {
      var $mobile_nav = $("#nav-menu-container").clone().prop({
        id: "mobile-nav",
      })
      $mobile_nav.find("> ul").attr({
        class: "",
        id: "",
      })
      $("body").append($mobile_nav)
      $("body").prepend(
        '<button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>'
      )
      $("body").append('<div id="mobile-body-overly"></div>')
      $("#mobile-nav")
        .find(".menu-has-children")
        .prepend('<i class="lnr lnr-chevron-down"></i>')

      $(document).on("click", ".menu-has-children i", function (e) {
        $(this).next().toggleClass("menu-item-active")
        $(this).nextAll("ul").eq(0).slideToggle()
        $(this).toggleClass("lnr-chevron-up lnr-chevron-down")
      })

      $(document).on("click", "#mobile-nav-toggle", function (e) {
        $("body").toggleClass("mobile-nav-active")
        $("#mobile-nav-toggle i").toggleClass("lnr-cross lnr-menu")
        $("#mobile-body-overly").toggle()
      })

      $(document).click(function (e) {
        var container = $("#mobile-nav, #mobile-nav-toggle")
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($("body").hasClass("mobile-nav-active")) {
            $("body").removeClass("mobile-nav-active")
            $("#mobile-nav-toggle i").toggleClass("lnr-cross lnr-menu")
            $("#mobile-body-overly").fadeOut()
          }
        }
      })
    } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
      $("#mobile-nav, #mobile-nav-toggle").hide()
    }
  }, [])
  return (
    <header id="header" className={scroll ? "header-scrolled" : "bg-black"}>
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-4 header-top-left no-padding">
              <a target="_blank" href="tel:+919841787093">
                call@(+91) 9841787093
              </a>
              <span style={{ marginRight: "10px", color: "#fff" }}>|</span>
              <a target="_blank" href="https://wa.me/+919655176317">
                whatsapp@(+91) 9655176317
              </a>
              <span style={{ marginRight: "10px", color: "#fff" }}>|</span>
              <a target="_blank" href="mailto:contact@madhuraminteriors.com">
                <span className="__cf_email__">
                  contact@madhuraminteriors.com
                </span>
              </a>
            </div>
            <div className="col-lg-6 col-sm-6 col-8 header-top-right no-padding">
              {/* <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-behance"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container main-menu">
        <div className="row align-items-center justify-content-between d-flex">
          <div id="logo">
            <Link to="/">
              <Image src="logo.png" alt="" title="" width={200} />
            </Link>
          </div>
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li className="menu-active">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/services/">Services</Link>
              </li>
              {/* <li>
                <Link to="/gallery/">Gallery</Link>
              </li> */}
              <li>
                <Link href="/contact/">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
